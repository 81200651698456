button {
  border: none;
  text-decoration: none;
  border: 1px solid grey;
  border-radius: 8px;
  background-color: #e4ffd1;
  padding: 4px 8px;
  font-weight: 500;
  margin-right: 4px;

  &.active {
    background-color: green;
    color: white;
  }
}

.main-container {
  width: 25vw;
  max-width: 25vw;
}
